<template>
  <div>
    <v-dialog v-model="dialog" width="600" open-on-click >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon @click="fetchData"><v-icon class="ml-1"
            color="blue">mdi-pen</v-icon></v-btn>
      </template>
      <v-card >
        <v-card-title class="d-flex justify-center">
          <h2>{{ $t('Edit Material') }}</h2>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-tabs background-color="transparent" v-model="tab" class="mb-3">
            <v-tab>{{ $t('Material') }}</v-tab>
            <v-tab>{{ $t('material specifications') }}</v-tab>
            <v-tab>{{ $t('Prices And Costs') }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-text-field v-model="accountData.name" :label="$t('Material Name')" outlined dense
                color="#757575"></v-text-field>
              <v-autocomplete :label="$t('Parent Folder')" outlined dense v-model="accountData.parent" color="#757575"
                item-text="name" item-value="id" :loading="itemsLoading" :items="accountItems" return-object no-filter
                v-debounce="fetchAutoComplete" @input="parentFolderInputHandler"></v-autocomplete>
              <v-autocomplete :label="$t('Type')" outlined dense v-model="accountData.type" color="#757575"
                item-text="name" item-value="id" :items="types"></v-autocomplete>
              <div class="d-flex justify-center align-center">
                <v-text-field v-model="accountData.serial" :label="$t('material serial')" outlined dense color="#757575"
                  :disabled="!accountData.parent"></v-text-field>
                <p class="pb-2">-{{ getParentSerial }}</p>
              </div>
              <v-autocomplete :label="$t('Material Type')" outlined dense v-model="accountData.material_type_id"
                color="#757575" item-text="name" item-value="id" :loading="material_typeLoading"
                :items="material_typeItems" return-object no-filter
                v-debounce="material_typeAutoComplete"></v-autocomplete>
              <v-autocomplete :label="$t('Tax In Code')" outlined dense color="#757575" item-text="code" item-value="id"
                :items="taxes" v-model="accountData.input_tax_id"></v-autocomplete>
              <v-autocomplete :label="$t('Tax Out Code')" outlined dense color="#757575" item-text="code"
                item-value="id" :items="taxes" v-model="accountData.output_tax_id"></v-autocomplete>
              <v-text-field v-model="accountData.tax_percent" :label="$t('Tax Percent')" outlined dense
                color="#757575"></v-text-field>
              <v-row>
                <v-col md="12">
                  <h4 style="text-align: center">{{ $t('Corresponding Account') }}</h4>
                </v-col>
                <v-col md="6">
                  <v-autocomplete :label="$t('sales account')" outlined dense v-model="accountData.sales_account_id"
                    color="#757575" item-text="name" item-value="id" :loading="salesLoading" :items="salesItems"
                    return-object no-filter v-debounce="salesFetchAutoComplete"></v-autocomplete>
                </v-col>
                <v-col md="6">
                  <v-autocomplete :label="$t('sales refund account')" outlined dense
                    v-model="accountData.sales_refund_account_id" color="#757575" item-text="name" item-value="id"
                    :loading="salesRefundLoading" :items="salesRefundItems" return-object no-filter
                    v-debounce="salesRefundFetchAutoComplete"></v-autocomplete>
                </v-col>
                <v-col md="6">
                  <v-autocomplete :label="$t('purchases account')" outlined dense
                    v-model="accountData.purchases_account_id" color="#757575" item-text="name" item-value="id"
                    :loading="purchaseLoading" :items="purchasesItems" return-object no-filter
                    v-debounce="purchaseFetchAutoComplete"></v-autocomplete>
                </v-col>
                <v-col md="6">
                  <v-autocomplete :label="$t('purchases refund account')" outlined dense
                    v-model="accountData.purchases_refund_account_id" color="#757575" item-text="name" item-value="id"
                    :loading="purchaseRefundLoading" :items="purchasesRefundItems" return-object no-filter
                    v-debounce="purchaseRefundFetchAutoComplete"></v-autocomplete>
                </v-col>
              </v-row>
              <v-card-actions class="d-flex justify-end">
                <v-btn :loading="submitAccountBtnLoading" depressed color="blue-grey" class="white--text"
                  @click="addAccount">{{ $t("storage") }}</v-btn>
                <v-btn depressed color="primary" @click="dialog = false">{{
      $t("cancel")
    }}</v-btn>
              </v-card-actions>
            </v-tab-item>
            <v-tab-item>
              <div class="d-flex justify-space-between align-center mb-5">
                <h4 style="color: var(--bg-color--font)">{{ $t('material specifications') }}</h4>
                <v-icon class="account-specifications__add-icon"
                  @click="newSpecification = true">mdi-plus-thick</v-icon>
              </div>
              <v-fade-transition group mode="out-in">
                <v-row v-for="(specification, index) in accountSpecifications" :key="index">
                  <v-col md="3" class="py-0">
                    <v-text-field outlined dense v-model="specification.key"></v-text-field>
                  </v-col>
                  <v-col md="5" class="py-0">
                    <v-text-field outlined dense v-model="specification.value"></v-text-field>
                  </v-col>
                  <v-col md="4" class="d-flex pt-0" style="align-items: center">
                    <v-btn color="green" dark depressed rounded v-if="!specification.material_id"
                      @click="createAttr(specification)">
                      {{ $t('Store Attribute') }}
                    </v-btn>
                    <v-btn @click="editAttr(specification)" color="blue" dark depressed rounded v-else>
                      {{ $t('Edit Attribute') }}
                    </v-btn>
                    <v-icon color="red" @click="deleteAttribute(specification, index)">mdi-delete</v-icon>
                  </v-col>
                </v-row>
              </v-fade-transition>
            </v-tab-item>
            <v-tab-item>
              <v-text-field :label="$t('sale price')" outlined dense type="number"
                v-model="accountData.retail_price"></v-text-field>
              <v-text-field :label="$t('Wholesale Price')" outlined dense type="number"
                v-model="accountData.wholesale_price"></v-text-field>
              <v-text-field :label="$t('Private Price')" outlined dense type="number"
                v-model="accountData.private_price"></v-text-field>
              <v-text-field :label="$t('cost')" outlined dense readonly v-model="accountData.cost"></v-text-field>
              <v-btn :loading="submitAccountBtnLoading" depressed color="blue-grey" class="white--text"
                @click="addAccount">{{
      $t("storage") }}</v-btn>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Notifications from "../../../../helpers/toast-notifications";

export default {
  props: {
    material: {
      type: Object,
    },
  },
  data() {
    return {
      tab: 0,
      accountData: {
        id: null,
        name: "",
        parent: {
          id: null,
          serial: null,
        },
        serial: null,
        type: null,
        attributes: [],
        retail_price: null,
        cost: null,
        sales_account_id: null,
        material_type_id: null,
        purchases_account_id: null,
        wholesale_price: null,
        private_price: null,
        sales_refund_account_id: null,
        purchases_refund_account_id: null,
        tax_percent: null,
        input_tax_id: null,
        output_tax_id: null,
      },
      material_type: null,
      material_typeLoading: false,
      material_typeItems: [],
      salesLoading: false,
      salesItems: [],
      purchaseLoading: false,
      purchasesItems: [],
      salesRefundLoading: false,
      salesRefundItems: [],
      purchaseRefundLoading: false,
      purchasesRefundItems: [],
      parentSerial: null,
      types: [
        {
          name: "مستودعية",
          id: "INVENTORY",
        },
        {
          name: "خدمية",
          id: "SERVICE",
        },
      ],
      newSpecification: false,
      dialog: false,
      itemsLoading: false,
      accountItems: [],
      accountSpecificationsArray: [],
      taxes: [],
      submitAccountBtnLoading: false,
    };
  },
  computed: {
    getParentSerial() {
      return (this.parentSerial = this.accountData.parent?.serial);
    },
    accountSpecifications() {
      if (this.newSpecification) {
        let accountSpecification = {
          key: "",
          value: "",
        };
        this.accountData.attributes.push(accountSpecification);
        this.newSpecification = false;
      }
      return this.accountData.attributes;
    },
  },

  methods: {
    async fetchData() {
      let params = {
        paginated: 'false',
      };
      if (this.returnAbility('tax:index')) {
        let res = await axios.get(`/accounting/tax`, { params });
        this.taxes = res.data.data;
      }
        const result = await axios.get(
          `/inventories/folders/${this.material.parent_id}`
        );

        const resultMaterial = await axios.get(
          `/inventories/materials/${this.material.id}`
        );
        const materialData = resultMaterial.data.data;
        this.accountData.id = materialData.id;
        this.accountData.retail_price = materialData.retail_price;
        this.accountData.cost = materialData.cost;
        this.accountData.name = materialData.name;
        this.accountData.parent.id = materialData.parent_id;
        this.accountData.serial = materialData.serial;
        this.accountData.attributes = materialData.material_attributes;
        this.accountData.type = materialData.type;
        this.accountData.wholesale_price = materialData.wholesale_price;
        this.accountData.private_price = materialData.private_price;

        const parent = result.data.data;
        this.accountData.parent.serial = parent.serial;
        const parentSerialLength = parent.serial.length;
        this.accountData.serial = this.accountData.serial.slice(
          parentSerialLength,
          this.accountData.serial.length
        );
        this.accountItems.push(parent);

        if (materialData.sales_account) {
          this.accountData.sales_account_id = materialData.sales_account;
          this.salesItems.push(materialData.sales_account);
        }
        if (materialData.material_type) {
          this.accountData.material_type_id = materialData.material_type;
          materialData_typeItems.push(materialData.material_type);
        }
        if (materialData.sales_refund_account) {
          this.accountData.sales_refund_account_id =
            materialData.sales_refund_account;
          this.salesRefundItems.push(materialData.sales_refund_account);
        }
        if (materialData.purchases_account) {
          this.accountData.purchases_account_id =
            materialData.purchases_account;
          this.purchasesItems.push(materialData.purchases_account);
        }
        if (materialData.purchases_refund_account) {
          this.accountData.purchases_refund_account_id =
            materialData.purchases_refund_account;
          this.purchasesRefundItems.push(
            materialData.purchases_refund_account
          );
        }

        
    },
    async parentFolderInputHandler() {
      const result = await axios.get(
        `/inventories/folders/${this.accountData.parent.id}`
      );
      const tempAccounts = result.data.data;
      if (tempAccounts.sales_account) {
        this.accountData.sales_account_id = tempAccounts.sales_account;
        this.salesItems.push(tempAccounts.sales_account);
      }
      if (tempAccounts.material_type) {
        this.accountData.material_type_id = tempAccounts.material_type;
        this.material_typeItems.push(tempAccounts.material_type);
      }
      if (tempAccounts.sales_refund_account) {
        this.accountData.sales_refund_account_id =
          tempAccounts.sales_refund_account;
        this.salesRefundItems.push(tempAccounts.sales_refund_account);
      }
      if (tempAccounts.purchases_account) {
        this.accountData.purchases_account_id = tempAccounts.purchases_account;
        this.purchasesItems.push(tempAccounts.purchases_account);
      }
      if (tempAccounts.purchases_refund_account) {
        this.accountData.purchases_refund_account_id =
          tempAccounts.purchases_refund_account;
        this.purchasesRefundItems.push(tempAccounts.purchases_refund_account);
      }
      console.log(result, "RESULT");
    },
    
    async material_typeAutoComplete(value) {
      try {
        if (!value) return;
        this.material_typeLoading = true
        const searchResult = await axios.get(
          "inventories/material-types/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.material_typeItems = searchResult.data.data;
      } catch (err) { }
      finally {
        this.material_typeLoading = false
      }
    },
    async salesFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.salesLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.salesItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.salesLoading = false;
      }
    },
    async salesRefundFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.salesRefundLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.salesRefundItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.salesRefundLoading = false;
      }
    },
    async purchaseFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.purchaseLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.purchasesItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.purchaseLoading = false;
      }
    },
    async purchaseRefundFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.purchaseRefundLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.purchasesRefundItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.purchaseRefundLoading = false;
      }
    },
    async addAccount() {
      try {
        this.submitAccountBtnLoading = true;

        await axios.put(`/inventories/materials/${this.accountData.id}`, {
          name: this.accountData.name,
          parent_id: this.accountData.parent.id,
          serial: this.accountData.parent.serial + this.accountData.serial,
          //   attributes: this.accountData.attributes,
          type: this.accountData.type,
          retail_price: this.accountData.retail_price,
          private_price: this.accountData.private_price,
          wholesale_price: this.accountData.wholesale_price,
          sales_account_id: this.accountData.sales_account_id?.id,
          material_type_id: this.accountData.material_type_id?.id,
          purchases_account_id: this.accountData.purchases_account_id?.id,
          sales_refund_account_id: this.accountData.sales_refund_account_id?.id,
          purchases_refund_account_id:
            this.accountData.purchases_refund_account_id?.id,
        });
        this.dialog = false;
        this.$emit("reFetchTree");
        Notifications(
          this.$t('edit success'),
          { timeout: 2000, rtl: true },
          "success"
        );
      } catch (err) {
      } finally {
        this.submitAccountBtnLoading = false;
      }
    },
    async deleteAttribute(specification, index) {
      if (specification.id) {
        await axios.delete(
          `/inventories/materials/attribute/${specification.id}`
        );
        this.accountData.attributes.splice(index, 1);
      }
    },
    async createAttr(spec) {
      await axios.post(`/inventories/materials/${this.material.id}/attribute`, {
        key: spec.key,
        value: spec.value,
      });
    },
    async editAttr(spec) {
      await axios.put(`/inventories/materials/attribute/${spec.id}`, {
        key: spec.key,
        value: spec.value,
      });
    },
    async fetchAutoComplete(value) {
      try {
        if (!value) return;
        this.itemsLoading = true;
        const searchResult = await axios.get(
          "inventories/folders/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.accountItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.itemsLoading = false;
      }
    },
  },
  mounted() {
    console.log("mounted");
  },
};
</script>

<style>
.account-specifications__add-icon {
  color: #757575;
  border: 1px solid #757575;
  border-radius: 50%;
  padding: 3px;
}

.account-specifications__add-icon:hover {
  transform: scale(1.1);
}
</style>
