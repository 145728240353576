<template>
  <div>
    <div class="d-flex justify-end align-center pt-5 pl-5">
      <v-col>
        <AutoCompleteField class="pb-6" :label="$t('search')" outlined rounded v-model="materials"
          endPoint="/inventories/materials/auto-complete" returnObject @input="onMaterialInput"/>
      </v-col>
      <app-add-folder class="pb-5 ml-5" @reFetchTree="fetchData"></app-add-folder>
      <app-add-material class="pb-5" @reFetchTree="fetchData"></app-add-material>
    </div>
    <v-card class="px-15 py-10" :loading="fetchingChild">
      <template slot="progress">
        <v-progress-linear color="#272727" indeterminate></v-progress-linear>
      </template>
      <template v-for="(node, i) in treeView">
        <app-tree-view v-if="true" :key="node.uid + i" :node="node" :expandChildren="node.expandChildren"
          @fetch-my-children="fetchChildren" @folderOpened="folderChildrenOpened"
          @reFetchTree="fetchData"></app-tree-view>
      </template>
    </v-card>
    <v-row justify="center"> </v-row>
    <v-row>
      <v-dialog width="700" v-model="dialog">
        <v-card v-if="materials">
          <v-card-actions class="justify-center">
            <v-row>
              <v-col md="1" class="py-5">
                {{ materials.id }}
              </v-col>
              <v-col md="6" class="py-5">
                {{ materials.name }} - {{ materials.serial }}
              </v-col>
              <v-col md="3" class="d-flex">
                <!-- <v-btn icon><v-icon class="ml-1">mdi-eye</v-icon></v-btn> -->
                <EditMaterial @reFetchTree="$emit('reFetchTree')" :material="materials" />
                <deleteMaterialFolder @record-deleted="$emit('reFetchTree')" :type="mat" :id="materials.id" />
              </v-col>
              <v-col md="2">
                <v-btn depressed @click="cancelDialog">{{ $t("cancel") }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import tree from "./tree.vue";
import serchMat from "./searchMat.vue";
import spinner from "../../../../components/base/spinner.vue";
import AddFolderModal from "./AddFolderModal.vue";
import AddMaterial from "./AddMaterial.vue";
import AutoCompleteField from "../../../dashboard/components/core/AutoCompleteField.vue";
import EditMaterial from "./EditMaterial.vue";
import deleteMaterialFolder from "./delete.vue";
import i18n from "../../../../i18n";
export default {
  data() {
    return {
      mat_id: null,
      mat: "MATERIAL",
      dialog: false,
      materials: null,
      tree: [],
      copyTree: [],
      fetchingChild: false,
    };
  },
  components: {
    EditMaterial,
    deleteMaterialFolder,
    appAddFolder: AddFolderModal,
    appAddMaterial: AddMaterial,
    appSpinner: spinner,
    appTreeView: tree,
    AutoCompleteField,
    serchMat,
  },
  methods: {
    cancelDialog() {
      this.materials = null;
      this.dialog = false;
    },
    folderChildrenOpened(node) {
      if (!node.root_id) {
        node.expandChildren = true;
      } else {
        for (let treeItem of this.tree) {
          if (treeItem.id == node.root_id) {
            treeItem.expandChildren = true;
            this.loopThroughTree(treeItem.children, node.root_id);
          }
        }
      }
    },
    loopThroughTree(children, root_id) {
      for (let child of children) {
        child.expandChildren = true;
        child.root_id = root_id;
        this.loopThroughTree(child.children, root_id);
      }
    },
    async fetchChildren(item) {
      for (let treeItem of this.tree) {
        if (treeItem.id == item.root_id) {
          // treeItem.expandChildren = true;
          this.loopThroughTree(treeItem.children, item.root_id);
        }
      }
      try {
        this.fetchingChild = true;
        const nodeChild = await axios.get("/inventories/folders/index", {
          params: {
            folder: item.id,
          },
        });
        nodeChild.data.data.forEach((el) => {
          item.children = [...el.materialsChildren, ...el.foldersChildren];
        });
        for (let child of item.children) {
          child.children = [];
          child.root_id = item.root_id;
        }
        nodeChild.data.data.children = [];
        nodeChild.data.data.expandChildren = false;
        this.tree.forEach((el) => {
          el.uid = this.$uuid.v1();
        });
      } catch (err) {
      } finally {
        this.fetchingChild = false;
      }
    },
    async onMaterialInput() {
      const result = await axios.get(
        `/inventories/materials/${this.materials.id}`
      );
      this.materials = result.data.data;
      this.dialog = true;
    },
    async fetchData() {
      try {
        // if (!this.returnAbility("folder:index")) {
        //   return;
        // }
        this.fetchingChild = true;
        const response = await axios.get("/inventories/folders/index");
        console.log(response);
        this.tree = response.data.data;
        this.tree.forEach((el) => {
          if (el.model == "FOLDER") {
            el.children = el.materialsChildren.concat(el.foldersChildren);
            el.expandChildren = false;
            el.children.forEach((element) => {
              element.root_id = el.id;
              element.children = [];
              element.expandChildren = false;
            });
          }
          el.uid = this.$uuid.v1();
        });
        this.tree.unshift({
          typeee: "heading",
          UUID: "ID",
          name: 'name',
          income: 'income',
          outcome: 'outcome',
          balance: "balance",
          id: 'id',
          settings: "settings",
          model: "mah",
        });
      } catch (err) {
      } finally {
        this.fetchingChild = false;
      }
    },
  },
  computed: {
    treeView() {
      let copyTree = [];
      copyTree = this.tree;
      return copyTree;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style></style>