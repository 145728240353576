<template>
  <div>
    <v-dialog v-model="dialog" width="600" open-on-click>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          depressed
          rounded
          class="add-modal__btn"
          @click="fetchData"
        >
          <v-icon color="#757575">mdi-plus</v-icon>
          <span>{{ $t('Create Material') }} </span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-center">
          <h2>{{ $t('Create Material') }} </h2>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-text-field
            v-model="accountData.name"
            :label="$t('Material Name')"
            outlined
            dense
            color="#757575"
          ></v-text-field>
          <v-autocomplete
            :label="$t('Parent Folder')"
            outlined
            dense
            v-model="accountData.parent"
            color="#757575"
            item-text="name"
            item-value="id"
            :loading="itemsLoading"
            :items="accountItems"
            return-object
            no-filter
            v-debounce="fetchAutoComplete"
            @input="parentFolderInputHandler"
          ></v-autocomplete>
          <v-autocomplete
            :label="$t('Type')"
            outlined
            dense
            v-model="accountData.type"
            color="#757575"
            item-text="name"
            item-value="id"
            :items="types"
          ></v-autocomplete>
          <v-text-field
            v-model="accountData.retail_price"
            :label="$t('retail price')"
            outlined
            dense
            color="#757575"
          ></v-text-field>
          <div class="d-flex justify-center align-center">
            <v-text-field
              v-model="accountData.serial"
              :label="$t('material serial')"
              outlined
              dense
              color="#757575"
              :disabled="!accountData.parent"
            ></v-text-field>
            <p class="pb-2">-{{ getParentSerial }}</p>
          </div>
          <v-autocomplete
            :label="$t('Material Type')"
            outlined
            dense
            v-model="accountData.material_type_id"
            color="#757575"
            item-text="name"
            item-value="id"
            :loading="material_typeLoading"
            :items="material_typeItems"
            return-object
            no-filter
            v-debounce="material_typeAutoComplete"
          ></v-autocomplete>
          <v-autocomplete :label="$t('Tax In Code')" outlined dense color="#757575" item-text="code" item-value="id"
                :items="taxes" v-model="accountData.input_tax_id"></v-autocomplete>
                <v-autocomplete :label="$t('Tax Out Code')" outlined dense color="#757575" item-text="code" item-value="id"
                :items="taxes" v-model="accountData.output_tax_id"></v-autocomplete>
                <v-text-field
              v-model="accountData.tax_percent"
              :label="$t('Tax Percent')"
              outlined
              dense
              color="#757575"
             
            ></v-text-field>
          <v-row>
            <v-col md="12">
              <h4 style="text-align: center; color: var(--bg-color--font);">{{ $t('Corresponding Account') }}</h4>
            </v-col>
            <v-col md="6">
              <v-autocomplete
                :label="$t('sales account')"
                outlined
                dense
                v-model="accountData.sales_account_id"
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="salesLoading"
                :items="salesItems"
                return-object
                no-filter
                v-debounce="salesFetchAutoComplete"
              ></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-autocomplete
                :label="$t('sales refund account')"
                outlined
                dense
                v-model="accountData.sales_refund_account_id"
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="salesRefundLoading"
                :items="salesRefundItems"
                return-object
                no-filter
                v-debounce="salesRefundFetchAutoComplete"
              ></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-autocomplete
                :label="$t('purchases account')"
                outlined
                dense
                v-model="accountData.purchases_account_id"
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="purchaseLoading"
                :items="purchasesItems"
                return-object
                no-filter
                v-debounce="purchaseFetchAutoComplete"
              ></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-autocomplete
                :label="$t('purchases refund account')"
                outlined
                dense
                v-model="accountData.purchases_refund_account_id"
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="purchaseRefundLoading"
                :items="purchasesRefundItems"
                return-object
                no-filter
                v-debounce="purchaseRefundFetchAutoComplete"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <div class="d-flex justify-space-between align-center mb-5">
            <h4 style="color: var(--bg-color--font)">{{ $t('material specifications') }}</h4>
            <v-icon
              class="account-specifications__add-icon"
              @click="newSpecification = true"
              >mdi-plus-thick</v-icon
            >
          </div>
          <v-fade-transition group mode="out-in">
            <v-row
              v-for="(specification, index) in accountSpecifications"
              :key="index"
            >
              <v-col md="3" class="py-0">
                <v-text-field
                  outlined
                  dense
                  v-model="specification.key"
                ></v-text-field>
              </v-col>
              <v-col md="7" class="py-0">
                <v-text-field
                  outlined
                  dense
                  v-model="specification.value"
                ></v-text-field>
              </v-col>
              <v-col md="2">
                <v-icon color="red" @click="deleteAttribute(index)"
                  >mdi-delete</v-icon
                >
              </v-col>
            </v-row>
          </v-fade-transition>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            :loading="submitAccountBtnLoading"
            depressed
            color="blue-grey"
            class="white--text"
            @click="addAccount"
            >{{ $t("storage") }}</v-btn
          >
          <v-btn depressed color="primary" @click="dialog = false">{{
            $t("cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Notifications from "../../../../helpers/toast-notifications";
import debounce from "../../../../helpers/debounce";

export default {
  data() {
    return {
      accountData: {
        id: null,
        name: "",
        parent: {
          id: null,
          serial: null,
        },
        serial: null,
        type: null,
        attributes: [],
        retail_price: null,
        sales_account_id: null,
        purchases_account_id: null,
        sales_refund_account_id: null,
        purchases_refund_account_id: null,
        material_type_id: null,
        tax_percent: null,
        input_tax_id: null,
        output_tax_id: null,
      },
      salesLoading: false,
      salesItems: [],
      material_type_id: null,
      material_typeLoading: false,
      material_typeItems: [],
      purchaseLoading: false,
      purchasesItems: [],
      salesRefundLoading: false,
      salesRefundItems: [],
      purchaseRefundLoading: false,
      purchasesRefundItems: [],
      parentSerial: null,
      types: [
        {
          name: "مستودعية",
          id: "INVENTORY",
        },
        {
          name: "خدمية",
          id: "SERVICE",
        },
      ],
      newSpecification: false,
      dialog: false,
      itemsLoading: false,
      accountItems: [],
      accountSpecificationsArray: [],
      taxes: [],
      submitAccountBtnLoading: false,
    };
  },
  computed: {
    getParentSerial() {
      return (this.parentSerial = this.accountData.parent?.serial);
    },
    accountSpecifications() {
      if (this.newSpecification) {
        let accountSpecification = {
          key: "",
          value: "",
        };
        this.accountData.attributes.push(accountSpecification);
        this.newSpecification = false;
      }
      return this.accountData.attributes;
    },
  },

  methods: {
    async fetchData(){
      let params = {
        paginated: 'false',
      };
      if(this.returnAbility('tax:index')){
        let res = await axios.get(`/accounting/tax`, { params });
        this.taxes = res.data.data;
      }
    },
    async parentFolderInputHandler() {
      const result = await axios.get(`/inventories/folders/${this.accountData.parent.id}`)
      const tempAccounts = result.data.data
      if (tempAccounts.sales_account) {
          this.accountData.sales_account_id = tempAccounts.sales_account;
          this.salesItems.push(tempAccounts.sales_account);
        }
        if (tempAccounts.material_type) {
          this.accountData.material_type_id = tempAccounts.material_type;
          this.material_typeItems.push(tempAccounts.material_type);
        }
        if (tempAccounts.sales_refund_account) {
          this.accountData.sales_refund_account_id =
          tempAccounts.sales_refund_account;
          this.salesRefundItems.push(tempAccounts.sales_refund_account);
        }
        if (tempAccounts.purchases_account) {
          this.accountData.purchases_account_id = tempAccounts.purchases_account;
          this.purchasesItems.push(tempAccounts.purchases_account);
        }
        if (tempAccounts.purchases_refund_account) {
          this.accountData.purchases_refund_account_id =
          tempAccounts.purchases_refund_account;
          this.purchasesRefundItems.push(tempAccounts.purchases_refund_account);
        }
      console.log(result, 'RESULT');
    },
    async salesFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.salesLoading = true
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.salesItems = searchResult.data.data;
      } catch (err) {}
      finally {
        this.salesLoading = false
      }
    },
    async material_typeAutoComplete(value) {
      try {
        if (!value) return;
        this.material_typeLoading = true
        const searchResult = await axios.get(
          "inventories/material-types/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.material_typeItems = searchResult.data.data;
      } catch (err) {}
      finally {
        this.material_typeLoading = false
      }
    },
    async salesRefundFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.salesRefundLoading = true
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.salesRefundItems = searchResult.data.data;
      } catch (err) {}
      finally {
        this.salesRefundLoading = false
      }
    },
    async purchaseFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.purchaseLoading = true
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.purchasesItems = searchResult.data.data;
      } catch (err) {}
      finally {
        this.purchaseLoading = false
      }
    },
    async purchaseRefundFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.purchaseRefundLoading = true
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.purchasesRefundItems = searchResult.data.data;
      } catch (err) {}
      finally {
        this.purchaseRefundLoading = false
      }
    },
    async addAccount() {
      try {
        this.submitAccountBtnLoading = true;
        await axios.post("/inventories/materials", {
          ...this.accountData,
          parent_id: this.accountData.parent.id,
          serial: this.accountData.parent.serial + this.accountData.serial,
          sales_account_id: this.accountData.sales_account_id?.id,
          material_type_id: this.accountData.material_type_id?.id,
          purchases_account_id: this.accountData.purchases_account_id?.id,
          sales_refund_account_id: this.accountData.sales_refund_account_id?.id,
          purchases_refund_account_id: this.accountData.purchases_refund_account_id?.id,
        });
        this.dialog = false;
        this.$emit("reFetchTree");
        Notifications(
          this.$t('add success'),
          { timeout: 2000, rtl: true },
          "success"
        );
      } catch (err) {
      } finally {
        this.submitAccountBtnLoading = false;
      }
    },
    deleteAttribute(index) {
      this.accountData.attributes.splice(index, 1);
    },
    async fetchAutoComplete(value) {
      try {
        if (!value) return;
        this.itemsLoading = true;
        const searchResult = await axios.get(
          "inventories/folders/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.accountItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.itemsLoading = false;
      }
    },
  },
};
</script>

<style>
.account-specifications__add-icon {
  color: #757575;
  border: 1px solid #757575;
  border-radius: 50%;
  padding: 3px;
}

.account-specifications__add-icon:hover {
  transform: scale(1.1);
}
</style>
